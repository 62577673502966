import * as React from "react";
import Layout from "../organisms/Layout";
import { useEffect, useState } from "react";
import { PageSEO } from "../atoms/SEO";
import BuyerAbout from "../organisms/BuyerAbout";
import BuyerCompanies from "../new_organisms/Companies";
import CrowdStrike_logo from "../images/buyer/companies/CrowdStrike_logo.svg";
import datadoghq from "../images/buyer/companies/datadoghq-ar21.svg";
import Elastic from "../images/buyer/companies/Elastic-NV.svg";
import aws from "../images/buyer/companies/Amazon_Web_Services_Logo.svg";
import Google_Cloud_logo from "../images/buyer/companies/Google_Cloud_logo.svg";
import Gupshup from "../images/buyer/companies/Gupshup.svg";
import PaloAltoNetworks_2020_Logo from "../images/buyer/companies/PaloAltoNetworks_2020_Logo.svg";
import snyk from "../images/buyer/companies/snyk.svg";
import nova from "../images/buyer/companies/nova.svg";
import snowflake from "../images/buyer/companies/snowflake.svg";
import cockroachlabs from "../images/buyer/companies/cockroach-labs.svg";
import sysdig from "../images/buyer/companies/sysdig-inc-logo-vector.svg";
import mongodb from "../images/buyer/companies/mongodb.svg";
import builder from "../images/buyer/companies/builder.svg";
import salesforce from "../images/buyer/companies/salesforce.svg";
import PictorialFeatureCards from "../new_organisms/PictorialFeatureCards";
import feature2 from "../images/home/features/feature2.svg";
import feature3 from "../images/home/features/feature3.svg";
import feature4 from "../images/home/features/feature4.svg";
import feature1 from "../images/home/features/feature1.svg";
import Steps from "../new_organisms/Steps/secondary";
// import Steps from "../new_organisms/Steps";
import step1 from "../images/home/steps/step1.svg";
import step2 from "../images/home/steps/step2.svg";
import step3 from "../images/home/steps/step3.svg";
import Caricature from "../images/home/about/caricature";
import colored_caricature from "../images/home/colored/caricature.svg";
import card1 from "../images/home/colored/card1.svg";
import card2 from "../images/home/colored/card2.svg";
import corner from "../images/home/colored/corner.svg";
// import slide1 from "../images/home/discoverTheProcess/slide1.svg";
import BoldSplitTitle from "../atoms/BoldSplitTitle";
import Button from "../atoms/Button";
import Container from "../atoms/Container";
import aws1 from "../images/buyer/caseStudies/aws.svg";
import gupshup from "../images/buyer/caseStudies/gupshup.svg";
import builder1 from "../images/buyer/caseStudies/builder.svg";
import CaseStudies from "../new_organisms/CaseStudies";
import Wrapper from "../atoms/Wrapper";
import FAQs from "../new_organisms/FAQs";

const IndexPage = ({ location }: any) => {
  function displayWaitListForm() {
    //display the wait-list modal/form by setting showWaitListFrom to true
    // setShowWaitListForm(true);
    window.open(
      "https://calendly.com/mukund-saas/get-to-know-saaspay?month=2024-04",
      "_blank"
    );
  }

  return (
    <Layout>
      <PageSEO
        path={location.pathname}
        title={"SaaSPay's B2B Buy-Now-Pay-Later for SaaS merchants"}
        description={
          "Offer monthly payment flexibility on annual deals and realize annual contract value on Day 1 with SaaSPay's B2B Buy-Now-Pay-Later solution."
        }
      />
      <BuyerAbout
        type="seller"
        Caricature={<Caricature />}
        title={"Realize Annual  Contract Value on Day 1"}
        subtitle={
          "Offer Monthly Payment Flexibility on annual deals & Eliminate USD Billings with BNPL for SaaS & cloud."
        }
        displayWaitListForm={displayWaitListForm}
        ctaPrimary={"Book a Demo"}
        ctaSecondary={"Learn more"}
      />
      <BuyerCompanies
        companies={[
          {
            logo: aws,
            url: "https://netflix.com",
          },
          {
            logo: mongodb,
            url: "https://netflix.com",
          },
          {
            logo: builder,
            url: "https://netflix.com",
          },
          {
            logo: salesforce,
            url: "https://netflix.com",
          },
          {
            logo: snyk,
            url: "https://netflix.com",
          },
          {
            logo: cockroachlabs,
            url: "https://netflix.com",
          },
          {
            logo: snowflake,
            url: "https://netflix.com",
          },
          {
            logo: Gupshup,
            url: "https://netflix.com",
          },
          {
            logo: Google_Cloud_logo,
            url: "https://netflix.com",
          },
          {
            logo: PaloAltoNetworks_2020_Logo,
            url: "https://netflix.com",
          },
          {
            logo: sysdig,
            url: "https://netflix.com",
          },
          {
            logo: Elastic,
            url: "https://netflix.com",
          },
          {
            logo: CrowdStrike_logo,
            url: "https://netflix.com",
          },
          {
            logo: nova,
            url: "https://netflix.com",
          },
          {
            logo: datadoghq,
            url: "https://netflix.com",
          },
        ]}
      />
      <PictorialFeatureCards
        features={[
          {
            title: "*Increase Annual* Subscription Sales",
            body: "Upfront payments are the only reason your customers choose monthly plans over annual. *Eliminate upfront payments on annual SaaS subscriptions & still realize annual contract value on Day 1.*",
            readMore: "https://google.com",
            logo: feature2,
            color: "#EE7D7521",
          },
          {
            title: "*Convert monthly  into annual deals*",
            body: "*Offer annual deals with payment flexibility to customers on renewal cycles to convert from monthly plans with ease.*",
            readMore: "https://google.com",
            logo: feature3,
            color: "#A897F921",
          },
          {
            title: "*Eliminate Payment Chasing & Churn*",
            body: "*Realize annual contract value on day 1 to eliminate payment chasing, collection reminders, and churn.* Manage your team’s time and resources with efficiency.",
            readMore: "https://google.com",
            logo: feature4,
            color: "#F9DB8821",
          },
          {
            title: "*12x AOV & CLTV* on Day 1",
            body: "*Increase average order and customer lifetime value by 12x on Day 1 by realizing forecasted revenue of monthly paying customers.*",
            readMore: "https://google.com",
            logo: feature1,
            color: "#9DE2FD21",
          },
        ]}
        subheading={"Without upfront payments"}
        heading={"Convert *monthly deals* to *annual deals*"}
      />

      <Steps
        title={"Magic in Action: The SaasPay Way!"}
        subtitle={"How does it work?"}
        steps={[
          {
            title: "*On checkout & In sales pitch*",
            body: "SaaSPay can be introduced to your customers in both sales & product-led motions.",
            logo: step1,
          },
          {
            title: "*Seamless Checkout*",
            body: "Effortlessly integrate SaaSPay's BNPL payment option with your existing checkout solution. ",
            logo: step2,
          },
          {
            title: "*Earn ACV on Day 1*",
            body: "Realize forecasted revenue of monthly paying customers on Day 1 of the subscription.",
            logo: step3,
          },
        ]}
      />
      <ColoredJoinTheWaitListSection
        title={"Bye USD Billings!👋 Hello, Smooth *INR Transactions!*"}
        cards={[
          {
            image: card1,
            heading: "For customers For You",
            text: "✓ Close deals faster with payment flexibility.",
          },
          {
            image: card2,
            heading: "",
            text: " Eliminate Withholding taxes of 20%- Realise contract value in USD",
          },
        ]}
      />
      <Checklist
        preTitle={"Maximise"}
        title={"Conversions, Productivity & Cashflow"}
        description={
          "SaaSPay acts as a B2B SaaS sales accelerator for teams driving high-velocity sales"
        }
        cards={[
          {
            title: "Sales Team",
            color: "rgba(157,226,253,0.13)",
            features: [
              "No more Complex ACH Transfers - Collect in INR from your customers",
              "Convert price-sensitive leads",
              "Improve customer retention",
            ],
          },
          {
            title: "CSM Team",
            color: "#F4F1FE",
            features: [
              "Convert monthly to annual on renewal cycles",
              "No recollections & payment chasing",
              "Eliminate payment-related churn",
            ],
          },
          {
            title: "Finance Team",
            color: "#FEFAF0",
            features: [
              "Realise forecasted revenue of monthly customers",
              "Receive monthly billables as one-time payments",
              "Increased cash flow on Day 1.",
            ],
          },
        ]}
      />
      {/* <DiscoverTheProcess
        headline={"Discover the Process"}
        title={"Sell *Smarter*, Convert *Faster*"}
        subtitle={
          "Go from Prospect to Purchase with upfront payments. Convert monthly subscribers to annual customers with one-click flexible financing. "
        }
        cards={[
          {
            title: "Sign Up",
            subtitle:
              "Register and complete a straightforward Know Your Business (KYB) process to set up your SaaSPay account and get ready to go.",
            image: slide1,
          },
          {
            title: "Sign Up2",
            subtitle:
              "Register and complete a straightforward Know Your Business (KYB) process to set up your SaaSPay account and get ready to go.",
            image: slide1,
          },
          {
            title: "Sign Up3",
            subtitle:
              "Register and complete a straightforward Know Your Business (KYB) process to set up your SaaSPay account and get ready to go.",
            image: slide1,
          },
        ]}
      /> */}
      <CaseStudies
        title={"Seller Spotlight"}
        subtitle={
          "Discover how SaaSPay's payment solutions enabled sellers to smash their sales targets. Hear firsthand accounts of how we empowered them to achieve unprecedented SaaS growth."
        }
        studies={[
          {
            title:
              "How AWS Marketplace eliminates USD & enables payment flexibility using SaaSPay",
            body: "AWS Marketplace bid adieu to USD billings & upfront payments cramping up cash flow. MP simplified transactions by offering customers the option to make INR payments which was previously not possible. With an added layer of payment flexibility, AWS was able to earn ACV on Day 1 while helping customers avail better discounts and payment flexibility ",
            logo: aws1,
          },
          {
            title:
              "How Builder improved annual subscription sales using SaaSPay",
            body: "By simply integrating SaaSPay onto their existing checkout, Builder now receives the ACV on day 1, on all their deals. By eliminating monthly churn and doubling their annual deals, Builder has increased their ARR exponentially. ",
            logo: builder1,
          },
          {
            title:
              "How Gupshup boosted Retention and slashed churn with SaaSPay",
            body: "By selling annual plans with the perk of Buy Now, Pay Later (BNPL) through SaaSPay, Gupshup  increased their Annual Recurring Revenue (ARR). Integrating SaaSPay with their existing checkout journey allowed Gupshup to receive the Annual Contract Value (ACV) on day 1, eliminating monthly churn, doubling their annual deals and improving retention.",
            logo: gupshup,
          },
        ]}
      />
      <FAQs
        title={"Your top questions, answered."}
        subtitle={""}
        faqs={[
          {
            question: "What is SaaSPay?",
            answer:
              "SaaSPay is the ultimate sales accelerator for SDRs, AEs and CSM agents. A Buy Now Pay Later solution designed for SaaS, it helps AEs & SDRs convert interest into deals and CSM agents convert monthly subscriptions into annual subscriptions. All while, getting paid in full, on Day 1 for the annual contact value.",
          },
          {
            question: "Is SaaSPay for SLG or PLG SaaS sellers?",
            answer:
              "We enable SaaS financing for all kinds of SaaS offerings, including Product-led and sales-led deals.",
          },
          {
            question: "How do I become a SaaSPay Seller?",
            answer:
              "Sign up on our website and complete your onboarding to start providing payment flexibility to your buyers, today.",
          },
          {
            question: "Is there a cost incurred to use SaaSPay",
            answer:
              "There is no recurring cost to use SaaSPay. SaaSPay simply collects a transaction fee for deal facilitated through the platform.",
          },
          {
            question: "Can I speak to someone at SaaSPay?",
            answer:
              "Yes, for sales-related queries, you can email us at sales@saaspay.app. For product support, please email us at support@saaspay.app",
          },
        ]}
      />
    </Layout>
  );
};
function Checklist({
  preTitle,
  title,
  description,
  cards,
}: {
  preTitle: string;
  title: string;
  description: string;
  cards: { title: string; features: string[]; color: string }[];
}) {
  return (
    <Wrapper className="px-4 xl:px-0">
      <Container
        className={"py-16 md:pt-20 md:pb-24 grid lg:grid-cols-2 gap-6"}
      >
        <div className={"flex items-center"}>
          <div className={"text-center md:text-start"}>
            <div className={"gilroy-regular text-[24px] md:text-[30px] mb-3"}>
              {preTitle}
            </div>
            <div
              className={"gilroy-bold text-[30px] md:text-[48px] mb-5 md:mb-4"}
            >
              {title}
            </div>
            <div className={"font-inter text-[18px] md:text-[20px] "}>
              {description}
            </div>
          </div>
        </div>
        {cards.map((card) => (
          <div
            style={{ background: card.color }}
            className={"py-10 px-12 rounded-[20px]"}
          >
            <h4
              className={
                "text-[30px] md:text-[36px] gilroy-bold lg:gilroy-semibold text-body2 mb-6 text-center md:text-left"
              }
            >
              {card.title}
            </h4>
            <div>
              {card.features.map((feature) => (
                <div className={"flex gap-4 mb-5"}>
                  <svg
                    className={"shrink-0 mt-1"}
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="11" cy="11" r="11" fill="#00B41D" />
                    <path
                      d="M7.85696 11.6531C8.76336 12.5732 9.53673 13.3583 9.53673 13.3583L14.1427 8.64404"
                      stroke="white"
                      stroke-width="1.63076"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div className={"text-body2 md:text-[18px]"}>{feature}</div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </Container>
    </Wrapper>
  );
}
function ColoredJoinTheWaitListSection({
  title,
  cards,
}: {
  title: string;
  cards: { image: string; heading: string; text: string }[];
}) {
  return (
    <Wrapper
      className={
        "bg-[#1e0146] text-[#F8F4FF] relative px-4 xl:px-0 overflow-x-hidden"
      }
    >
      <img
        src={corner}
        className={"absolute -right-1/3 lg:right-0 bottom-0 "}
      />
      <Container
        className={
          "py-16 md:pt-20 lg:pt-24 md:pb-32 md:py-16 relative md:h-[820px]"
        }
      >
        <img
          src={colored_caricature}
          className={
            "absolute right-0 lg:-right-10 md:block hidden z-[1] max-w-[380px] lg:max-w-none"
          }
        />
        <div
          className={
            "max-w-[270px] md:max-w-[300px] lg:max-w-[449px] mb-6 z-[3] relative"
          }
        >
          <BoldSplitTitle
            bold={(text) => (
              <div
                className={
                  "text-[32px] md:!leading-[62px] lg:!leading-[72px] md:text-[36px] lg:text-[48px] xl:text-[56px] text-[#6FFD86] gilroy-bold"
                }
              >
                {text}
              </div>
            )}
            normal={(text) => (
              <div
                className={
                  "text-[32px] md:!leading-[62px] lg:!leading-[72px] md:text-[36px] lg:text-[48px] xl:text-[56px] gilroy-bold"
                }
              >
                {text}
              </div>
            )}
            text={title}
          />
        </div>
        <Button
          className={"mb-16 lg:mb-32 !rounded-full text-p1"}
          variant={"light"}
          id={"join-waitlist-buyer-section"}
          width={174}
          height={48}
          onClick={() =>
            window.open("https://form.jotform.com/240982279984475", "_blank")
          }
        >
          Request a Demo
        </Button>

        <div className={"flex flex-col z-[2] relative"}>
          <div className={"gilroy-bold text-[20px] lg:text-[24px] mb-6"}>
            For Customer For You
          </div>
          <div className={"flex flex-wrap gap-7"}>
            {cards.map((card) => (
              <div
                className={
                  "rounded-2xl p-6 w-full md:max-w-[370px] bg-[#2F0E5B]"
                }
              >
                <div
                  className={
                    "mb-4 flex items-center justify-center rounded-lg w-10 h-10 bg-[#F8F4FF] bg-opacity-10"
                  }
                >
                  <img className={""} src={card.image} />
                </div>
                <div
                  className={
                    "text-[#F8F4FF] font-inter font-semibold opacity-80"
                  }
                >
                  {card.text}
                </div>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </Wrapper>
  );
}
function DiscoverTheProcess({
  headline,
  title,
  subtitle,
  cards,
}: {
  headline: string;
  title: string;
  subtitle: string;
  cards: { title: string; subtitle: string; image: string }[];
}) {
  const [selectedCard, setSelectedCard] = useState(0);
  const [dirty, setDirty] = useState(false);
  const card = cards[selectedCard];
  useEffect(() => {
    if (dirty) return;
    const interval = setInterval(() => {
      setSelectedCard((o) => (o + 1) % cards.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [dirty]);
  return (
    <Wrapper>
      <Container className={"pt-16 md:pt-36 pb-28 md:pb-80"}>
        <div
          className={
            "text-[#EDE8F8] text-opacity-50 gilroy-bold text-[44px] md:text-[60px] lg:text-[72px] xl:text-[114px] mb-4 lg:mb-32 text-center lg:text-start"
          }
        >
          {headline}
        </div>
        <div
          className={
            "flex justify-center lg:justify-between items-center flex-wrap gap-12"
          }
        >
          <div>
            <div
              className={
                "md:w-[449px] mb-4 leading-[1.18] text-center lg:text-left"
              }
            >
              <BoldSplitTitle
                bold={(text) => (
                  <span
                    className={
                      "gilroy-bold text-[36px] md:text-[48px] lg:text-[64px] mb-3 text-p1"
                    }
                  >
                    {text}
                  </span>
                )}
                normal={(text) => (
                  <span
                    className={
                      "gilroy-bold text-[36px] md:text-[48px] lg:text-[64px]"
                    }
                  >
                    {text}
                  </span>
                )}
                text={title}
              />
            </div>
            <div
              className={
                "md:w-[359px] gilroy-regular text-[18px] md:text-[20px] lg:text-[24px] opacity-50  text-center lg:text-left"
              }
            >
              {subtitle}
            </div>
          </div>
          <div
            className={
              "bg-[#F0F1F5] px-9 pb-16 relative rounded-[20px] max-w-[494px]"
            }
          >
            <img
              key={selectedCard}
              src={card.image}
              className={"mb-10 fade-in-animation2"}
            />
            <div
              key={selectedCard}
              className={
                "mb-4 gilroy-bold text-[24px] md:text-[30px] fade-in-animation2"
              }
            >
              {card.title}
            </div>
            <div
              key={selectedCard}
              className={
                "mb-4 gilroy-regular text-[18px] md:text-[20px] fade-in-animation2"
              }
            >
              {card.subtitle}
            </div>
            <div
              className={
                "absolute rounded-full bottom-0 shadow left-1/2 bg-white -translate-x-1/2 translate-y-1/2 px-9 py-2.5 flex gap-2"
              }
            >
              {cards.map((_, i) => (
                <div
                  onClick={() => {
                    setDirty(true);
                    setSelectedCard(i);
                  }}
                  style={{
                    height: i === selectedCard ? 18 : 15,
                    width: i === selectedCard ? 18 : 15,
                  }}
                  className={
                    "rounded-full cursor-pointer " +
                    (i === selectedCard ? "bg-p1" : "bg-[#F0F1F5]")
                  }
                />
              ))}
            </div>
          </div>
        </div>
      </Container>
    </Wrapper>
  );
}
export default IndexPage;
