import React from "react";
import caricature from "./caricature-svg.svg";
export default function bgImage() {
  return (
    <img
      className={"w-[90%] h-[400px] md:h-[760px] mt-10"}
      alt={"building caricature"}
      src={caricature}
      height={300}
      width={355.5}
    />
  );
}
